import React from 'react';
import {EducationSvg, ExperienceIcon} from "../media/svg";
import HoverAnimation from "../components/hoverAnimation";

const Resume = () => {
    const education = [
        {
            degree: "MS in Computer Science",
            timeline: "Aug 2023 - Dec 2024 (expected)",
            school: "Georgia Institute of Technology",
            location: "Atlanta, GA",
            specialization: "Specialization in Machine Learning",
            research: ["Natural Language Processing", "Large Language Models", "Human Activity Recognition", "Quantitative Finance"]
        },
        {
            degree: "B.Engg in Electronics & Communication",
            timeline: "Aug 2016 - Aug 2020",
            school: "PES Institute of Technology",
            location: "Bangalore, India",
            specialization: "",
            research: []
        }
    ]

    const experience = [
        {
            organization: "krtrimaIQ Cognitive Solutions",
            timeline: "Jan 2020 - Jul 2023",
            positions: [
                {
                    position: "Machine Learning Engineer",
                    domains: ["Algorithmic Trading", "Web Development", "Machine Learning", "Business Intelligence", "Amazon Web Services"],
                    timeline: "Aug 2020 - Jul 2023",
                },
                {
                    position: "Machine Learning Intern",
                    timeline: "Jan 2020 - Jul 2020",
                    domains: ["Machine Learning", "Time-series Analysis"]
                }
            ]
        }
    ]

    return (
        <div className="col-span-12 lg:col-span-8 ">
            <div className="lg:rounded-2xl bg-pure-white dark:bg-[#111111] px-8 lg:px-0">
                <div data-aos="fade" className="aos-init">
                    <div className="container px-4 sm:px-5 md:px-10 lg:px-14">
                        <div className={"py-12"}>
                            <h2 className="after-effect after:left-52">
                                Resume
                            </h2>
                            <div>
                                <div className="flex items-center space-x-2 my-4">
                                    <div>
                                        <ExperienceIcon/>
                                    </div>
                                    <h4 className="text-2xl dark:text-white font-medium">
                                        Experience
                                    </h4>
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <HoverAnimation opacity={0.4} className={"mb-6 rounded-xl bg-dark-gray"}>
                                        <HoverAnimation className="m-0.5 p-4 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                            {experience.map((item, index) => (
                                                <div key={index} className={"gap-6 flex flex-col"}>
                                                    <div className="text-xl dark:text-white font-medium">
                                                        {item.organization}
                                                    </div>
                                                    {item.positions.map((position, i) => (
                                                        <div key={i} className={"flex flex-col"}>
                                                            <div className={"flex justify-between items-center"}>
                                                                <div className="text-lg dark:text-white font-medium my-1">
                                                                    {position.position}
                                                                </div>
                                                                <div className="text-xs text-gray-lite dark:text-gray">
                                                                    {position.timeline}
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-start gap-6 my-3"}>
                                                                <div className={"flex whitespace-nowrap py-0.5"}>
                                                                    Expertise
                                                                </div>
                                                                <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
                                                                    {position.domains.map((domain, index) => (
                                                                        <div key={index} className="rounded-2xl bg-gradient-to-br from-hrudhai-blue/70 to-hrudhai-purple/70 text-sm text-white px-2 py-0.5">
                                                                            {domain}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </HoverAnimation>
                                    </HoverAnimation>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center space-x-2 my-4">
                                    <div>
                                        <EducationSvg/>
                                    </div>
                                    <h4 className="text-2xl dark:text-white font-medium">
                                        Education
                                    </h4>
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    {education.map((item, index) => (
                                        <HoverAnimation key={index} opacity={0.4} className={"mb-6 rounded-xl bg-dark-gray"}>
                                            <HoverAnimation className="m-0.5 p-4 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                                <div className={"gap-2 flex flex-col"}>
                                                    <div className="text-xs text-gray-lite dark:text-gray">
                                                        {item.timeline}
                                                    </div>
                                                    <div className={"flex justify-between items-center"}>
                                                        <div className="text-xl dark:text-white">
                                                            {item.degree}
                                                        </div>
                                                        <div className="dark:white">
                                                            {item.specialization}
                                                        </div>
                                                    </div>
                                                    <div className={"flex justify-between items-center"}>
                                                        <div className="dark:text-gray">
                                                            {item.school}
                                                        </div>
                                                        <div className="dark:text-gray">
                                                            {item.location}
                                                        </div>
                                                    </div>
                                                    <div className={`${item.research.length > 0 ? "flex flex-start gap-6 my-3": "hidden"}`}>
                                                        <div className={"flex whitespace-nowrap py-0.5"}>
                                                            Research Areas
                                                        </div>
                                                        <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
                                                            {item.research.map((research, index) => (
                                                                <div key={index} className="rounded-2xl bg-gradient-to-br from-hrudhai-blue/70 to-hrudhai-purple/70 text-sm text-white px-2 py-0.5">
                                                                    {research}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </HoverAnimation>
                                        </HoverAnimation>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume;