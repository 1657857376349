import React from 'react';
import {EducationSvg, ExperienceIcon, OpenSource} from "../media/svg";
import HoverAnimation from "../components/hoverAnimation";

const Projects = () => {
    const projects = [
        {
            title: "CogniScribe",
            timeline: "In Progress",
            description: "Developing a platform to summarize webpages and initiate conversations about the content of the webpage.",
            tech: ["GPT-3.5", "React.js", "TailwindCSS", "Python", "FastAPI", "Open-Source"],
            href: "#",
            openSource: true
        },
        {
            title: "Quantward - Algorithmic Trading Platform",
            role: "Tech Lead",
            timeline: "Aug 2020 - Jul 2023",
            description: "Developed over 15 algorithmic trading bots based on rule-based strategies and created a backtesting model for an algorithmic trading project.",
            tech: ["AWS Lambda", "Amazon API Gateway", "AWS S3", "Python", "Django", "PostgreSQL", "JavaScript"],
            href: "https://www.krtrimaiq.ai/solutions/algorithmic-trading",
            openSource: false
        },
        {
            title: "Construction Management System for Kirby India",
            role: "Solutions Architect",
            timeline: "Feb 2023 - Jul 2023",
            description: "Set up a hybrid architecture with the use of servers and serverless microservices with enterprise-level security, for a construction management platform for Kirby India.",
            tech: ["Amazon Web Services (AWS)", "MongoDB", "React.js"],
            href: "#",
            openSource: false
        },
        {
            title: "krtrimaIQ Intranet Portal",
            role: "Tech Lead",
            timeline: "May 2023 - Jul 2023",
            description: "Led the design and development of an intranet portal with functionalities like Leave Management, Trainings and Certifications, Reimbursements, and Payroll Management.",
            tech: ["Django", "PostgreSQL", "Tailwind.CSS", "JavaScript", "MongoDB"],
            href: "#",
            openSource: false
        },
        {
            title: "Redesign of krtrimaIQ Website",
            role: "Tech Lead",
            timeline: "Mar 2023 - Jun 2023",
            description: "Coordinated sprints and led the design and development of the company website as a part of a design revamp.",
            href: "https://www.krtrimaiq.ai/",
            tech: ["Next.JS", "Tailwind.CSS", "JavaScript", "MongoDB", "AWS Lambda", "Amazon S3", "Amazon API Gateway"],
            openSource: false
        },
        {
            title: "AutomateMyP&L - Publication Approval Platform",
            role: "Tech Lead",
            timeline: "Oct 2022 - Mar 2023",
            description: "An automated publishing-to-printing approval platform for a publication house.",
            tech: ["React.JS", "AWS Lambda", "Amazon S3", "Amazon API Gateway", "MongoDB"],
            href: "#",
            openSource: false
        },
        {
            title: "Vaccination Dashboard for COVID-19",
            role: "ETL Developer",
            timeline: "Oct 2021 - Mar 2022",
            description: "Developed an ETL pipeline to load vaccination data into the database and report them using a PowerBI dashboard.",
            tech: ["PowerBI", "Microsoft SQL Server"],
            href: "#",
            openSource: false
        },
        {
            title: "inCovid19",
            role: "Computer Vision Consultant",
            timeline: "Aug 2021 - Dec 2021",
            description: "Developed a script to extract state-wise COVID-19 numbers from images and websites to be reported on a website.",
            tech: ["OpenCV", "Google Vision", "Python"],
            href: "https://www.incovid19.org/",
            openSource: false
        }
    ]

    return (
        <div className="col-span-12 lg:col-span-8 ">
            <div className="lg:rounded-2xl bg-pure-white dark:bg-[#111111] px-8 lg:px-0">
                <div data-aos="fade" className="aos-init">
                    <div className="container px-4 sm:px-5 md:px-10 lg:px-14">
                        <div className={"py-12"}>
                            <h2 className="after-effect after:left-52">
                                Projects
                            </h2>
                            <div className="flex flex-col gap-y-2 my-4">
                                {projects.map((item, index) => (
                                    <HoverAnimation opacity={0.4} key={index} className={"mb-6 rounded-xl bg-dark-gray"}>
                                        <HoverAnimation className="m-0.5 p-4 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                            <div className={"gap-6 flex flex-col"}>
                                                <div className={"flex justify-between items-center"}>
                                                    <div className="flex gap-4 items-center">
                                                        <div className={"font-medium text-lg dark:text-white"}>
                                                            {item.title}
                                                        </div>
                                                        <div className={"relative pointer-events-none"}>
                                                            <a href={item.href} target={"_blank"} className={`${item.href !== '#' ? 'flex' : 'hidden'} group items-center justify-between gap-1 text-sm rounded-full bg-black dark:bg-white text-white dark:text-black px-2 pointer-events-auto`}>
                                                                Live
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 group-hover:stroke-grad">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="text-xs text-gray-lite dark:text-gray text-end">
                                                        {item.timeline}
                                                    </div>
                                                </div>
                                                <div className={""}>
                                                    {item.description}
                                                </div>
                                                <div className={"flex flex-wrap gap-x-2 gap-y-2"}>
                                                    {item.tech.map((tech, index) => (
                                                        <div key={index} className="rounded-2xl bg-gradient-to-br from-hrudhai-blue/70 to-hrudhai-purple/70 text-sm text-white px-2 py-0.5">
                                                            {tech}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                        </HoverAnimation>
                                    </HoverAnimation>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;